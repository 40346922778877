<template>
    <div class="container-fluid p-0 m-0">
        <section class="feature-title">
            <div style="background-image: url(https://static.vitaalumnicn.com/static/vsweb/city-bg.png);"
                 class="feature-title-background"></div>
        </section>

        <section class="city-header" v-if="city && headData">
            <div class="container mt-0 mb-0 ms-auto me-auto head">
                <div class="title">{{ cityData.name }}</div>
                <div class="sub-title" v-html="headData.content"></div>
            </div>
        </section>

        <section class="banner-img vh-80 p-0" v-if="city && city.pcBannerUrl">
            <img class="img" :src="city.pcBannerUrl" alt="">
        </section>

        <section class="section-black p-t-30" v-if="cityData">
            <div class="common-header">
                <div class="common-title" v-if="$i18n.locale === 'cn'">{{ cityData.name }}学生公寓</div>
                <div class="common-title" v-else>STUDENT ACCOMMODATION IN {{ cityData.name }}</div>
            </div>
            <div class="common-note" style="max-width: 355px;margin-top: -7px" v-html="section2Data.content"></div>

        </section>

        <section class="section-black" v-if="cityData && section3Data" style="margin-top: 275px">
            <div class="common-header">
                <div class="common-title" v-if="$i18n.locale === 'cn'">我们在{{ cityData.name }}的公寓</div>
                <div class="common-title" v-else>OUR BUILDING IN {{ cityData.name }}</div>
            </div>
            <div class="common-note" style="max-width: 450px" v-html="section3Data.content"></div>
        </section>

        <section class="section-black apartments" v-if="apartments && apartments.length > 0">
            <div v-for="(apartment, index) of apartments"
                 :key="index"
                 :class="index !== 0 ? 'm-t-50' : ''">
                <div class="glide-slides"
                     :class="index%2 === 0 ? 'me-auto' : 'ms-auto'">
                    <div :class="index%2 === 0 ? 'left-btn cursor position-left-20' : 'right-btn cursor position-right-20'">
                        <div class="btn-offset cursor" @click="prevSlide('citySwiper1', index)"> <</div>
                        <div class="btn-offset cursor" @click="nextSlide('citySwiper1', index)"> ></div>
                    </div>
                    <div class="carousel-container" :class="index%2 === 0 ? 'me-auto' : 'ms-auto'">
                        <swiper :options="swiperOption" ref="citySwiper1">
                            <swiper-slide v-for="(image, ind) in apartment.pcImgs" :key="ind">
                                <img class="img" :src="image.thumbnail" alt=""/>
                            </swiper-slide>
                        </swiper>
                    </div>

                    <div class="form-panel" :class="index%2 === 0 ? 'position-right-40' : 'position-left-40'">
                        <div class="form-card">
                            <div class="form-area hide-scroll" :id="'formArea_' + index">
                                <div class="close cursor font-weight-900 text-center" @click="closeSpeakToUs(index)">X
                                </div>
                                <div class="form-title">
                                    {{ $t('city.speakToUs') }}
                                </div>
                                <div class="form-group">
                                    <div class="form-label">{{ $t('common.name') }}</div>
                                    <input class="form-input" v-model="form.name" type="text">
                                </div>
                                <div class="form-group">
                                    <div class="form-label">{{ $t('common.phone') }}</div>
                                    <input class="form-input" v-model="form.cellphone" type="text">
                                </div>
                                <div class="form-group d-flex">
                                    <el-checkbox class="me-2 checked" v-model="checked"></el-checkbox>
                                    <div class="font-size-12">
                                        {{ $t('common.bySubmitting') }}
                                    </div>
                                </div>
                                <div class="submit-btn m-t-20" @click="handleSubmit(apartment.apartmentId)">{{ $t('common.notifyMe') }}</div>
                            </div>

                            <div class="form-content">
                                <div class="form-title">{{ apartment.name }}</div>
                                <div class="address">{{ apartment.address }}</div>
                                <div class="info-items row">
                                    <div class="info-item col-6 m-t-15">
                                        <div class="item-value">{{ apartment.floor }}</div>
                                        <div class="item-label">{{ $t('city.floors') }}</div>
                                    </div>

                                    <div class="info-item col-6 m-t-15">
                                        <div class="item-value">{{ apartment.roomNum }}</div>
                                        <div class="item-label">{{ $t('city.rooms') }}</div>
                                    </div>
                                </div>
                                <div class="desc">
                                    {{ apartment.note }}
                                </div>
                                <div class="footer-card">
                                    <div class="btn btn-red cursor"
                                         @click="$goto('/cities/' + city.slug+'/' + apartment.slug)">
                                        {{ $t('city.viewBuilding') }}
                                    </div>
                                    <div class="btn btn-black cursor m-t-15" @click="handleSpeakToUs(index)">
                                        {{ $t('city.speakToUs') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="section-black d-flex justify-content-between align-items-end">
            <div>
                <div class="common-header">
                    <div class="common-title">{{ $t('city.whatsIncluded') }}</div>
                </div>
                <div class="common-note" style="max-width: 450px">
                    {{ $t('city.whatsIncludedNote') }}
                </div>
            </div>
<!--            <div class="read-more d-inline-flex align-items-center">-->
<!--&lt;!&ndash;                todo 弹框&ndash;&gt;-->
<!--                <span>VIEW ALL</span>-->
<!--                <span class="area ms-2"></span>-->
<!--            </div>-->
        </section>

        <section class="img-wall-section">
            <img class="img" :src="currentImage" alt="">
            <div class="row">
                <div class="col-3 p-0" v-for="(item, ind) of imgWalls" :key="ind">
                    <div class="img-wall cursor" @mousemove="toggleHover(item.url)">
                        <div class="wall-title">{{ item.title }}</div>
                        <div class="wall-note" v-html="item.content"></div>
                    </div>
                </div>
            </div>
        </section>

        <!-- todo blog 相关-->
        <section v-show="false" class="section-black">
            <div class="common-header">
                <div class="common-title">LIVING AT VITA STUDENT BELFAST</div>
            </div>
            <div class="common-note" style="max-width: 450px">
                Vita Student Belfast is a short stroll from the Queen’s University campus and from Ulster University,
                with all of the essential day-to-day amenities within a short walking distance. We’re just 10 minutes
                from Cathedral Quarter, home of the city’s trendiest art galleries and restaurants, as well as Queen’s
                Quarter – the best place to go shopping in Belfast.
            </div>
            <div class="read-more d-inline-flex align-items-center">
                <span>READ MORE</span>
                <span class="area ms-2"></span>
            </div>
        </section>

        <!-- todo blog 相关-->
        <section v-show="false" class="vita-static-gallery container-fluid">
            <div class="row">
                <div class="left-panel col-4" style="padding-top: 0">
                    <div class="panel-head sticky-top-0" style="display: block;padding-top: 30px">
                        <div class="content-title">
                            WHAT WE’RE ABOUT
                        </div>

                        <div class="left-content">
                            Vita Student is for students that want it all. For students that want to live without
                            limits.
                            <br><br>
                            With an all-inclusive service, living at Vita Student lifts some of the pressures from
                            university life, allowing you to focus on your stu dies and your experience.
                            <br><br>
                            Our buildings are full of friendly faces from many different places. All here to make the
                            most of their time at university. Whether you are creative or academic, Vita Student is the
                            place to learn, thrive and make connections.
                        </div>
                    </div>
                </div>
                <div class="col-panel col-4 p-0">
                    <div class="img-card sticky-top-0">
                        <div class="card-footer">
                            <div class="note">
                                As University Applications Soar
                            </div>
                            <div class="link-btn d-flex align-items-center justify-content-center">
                                <span class="font iconfont">&#xe60d;</span>
                            </div>
                        </div>
                        <img class="img img-center" decoding="async" loading="lazy"
                             src="https://www.vitastudent.com/wp-content/uploads/2023/02/Leeds-2-Room-128-1-scaled.jpg">
                    </div>
                </div>
                <div class="col-panel col-4 p-0">
                    <div class="img-card" v-for="item of 3">
                        <div class="card-footer">
                            <div class="note">
                                As University Applications Soar, Student Accommodation Will Be More Scarce Than Ever
                            </div>
                            <div class="link-btn d-flex align-items-center justify-content-center">
                                <span class="font iconfont">&#xe60d;</span>
                            </div>
                        </div>
                        <img class="img-right" decoding="async" loading="lazy"
                             src="https://www.vitastudent.com/wp-content/uploads/2023/02/VITASTUDENT_PROFILES_NOV2021-243-scaled.jpg">
                    </div>
                </div>
            </div>
        </section>

        <section class="image-content section-red pt-0 p-b-1"
                 v-if="cityData && introduction && introduction.sections.length > 0">
            <div class="container-fluid m-0 ms-auto me-auto pt-0 p-0">
                <div class="items">
                    <div class="item-flex">
                        <div class="item-width-50"
                             v-slide-in="{direction: 'X',distance_x:['-50%', '0px'],distance_y:['0px', '0px'],duration: 1000}">
                            <div class="left-title m-t-40" v-html="$t('city.ourNeighbourhood')"></div>
                            <div class="left-note">
                                {{ $t('city.welcomeTo') }} <br>
                                {{ cityData.name }}.
                            </div>
                        </div>
                        <div class="item-width-50">
                            <img class="img"
                                 style="width: calc(100% + 35px)"
                                 v-slide-in="{direction: 'X',distance_x:['50%', '0px'],distance_y:['0px', '0px'],duration: 1000}"
                                 :src="introduction.thumbnailUrl" alt="">
                        </div>
                    </div>
                    <div v-for="(section, index) of introduction.sections" :key="index">
                        <div class="item-flex" v-if="section.imagePosition === 'right'">
                            <div class="item-width-40 p-l-0 p-r-50"
                                 v-slide-in="{direction: 'X',distance_x:['-50%', '0px'],distance_y:['0px', '0px'],duration: 1000}">
                                <div class="item-title" v-if="$i18n.locale === 'cn'">“{{ section.title }}”</div>
                                <div class="item-title" v-else>"{{ section.title }}"</div>
                                <div class="item-note" v-html="section.desc"></div>
                            </div>
                            <div class="item-width-60 p-l-20 p-r-20">
                                <img class="img"
                                     v-slide-in="{direction: 'X',distance_x:['50%', '0px'],distance_y:['0px', '0px'],duration: 1000}"
                                     :src="section.thumbnailUrl" :alt="section.thumbnailUrl">
                            </div>
                        </div>

                        <div class="item-flex" v-else>
                            <div class="item-width-60"
                                 v-slide-in="{direction: 'X',distance_x:['-50%', '0px'],distance_y:['0px', '0px'],duration: 1000}">
                                <img class="img" :src="section.thumbnailUrl" :alt="section.thumbnailUrl">
                            </div>
                            <div class="item-width-40 p-l-50 pe-0"
                                v-slide-in="{direction: 'X',distance_x:['50%', '0px'],distance_y:['0px', '0px'],duration: 1000}">
                                <div class="item-title" v-if="$i18n.locale === 'cn'">“{{ section.title }}”</div>
                                <div class="item-title" v-else>"{{ section.title }}"</div>
                                <div class="item-note" v-html="section.desc"></div>
                            </div>
                        </div>
                    </div>
                    <!--   todo blog-->
                    <div class="read-blog-panel" v-show="false"
                         v-slide-in="{direction: 'X',distance_x:['50%', '0px'],distance_y:['0px', '0px'],duration: 1000}">
                        <div class="read-blog">READ BLOG</div>
                        <div class="go-btn"><span class="icon iconfont">&#xe60d;</span></div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-black content-repeater" v-if="cityData && universities.length > 0">
            <div class="common-header">
                <div class="common-title" v-if="$i18n.locale === 'cn'">{{ cityData.name }}的大学</div>
                <div class="common-title" v-else>UNIVERSITIES IN {{ cityData.name }}</div>
            </div>

            <div class="items">
                <div v-for="(item, index) of universities" :key="index">
                    <div v-if="index % 2 === 0" class="item-position">
                        <div class="item-title"
                             v-slide-in="{direction: 'X',distance_x:['0px', '0px'],distance_y:['0px', '0px'],duration: 2000}"
                             style="top: -150px;left: 0">
                            {{ item.name }}
                        </div>
                        <div class="panel-1"
                             v-slide-in="{direction: 'X',distance_x:['10%', '0px'],distance_y:['0px', '0px'],duration: 1000}"
                             style="top: 10px;right: 90px"></div>
                        <img class="panel-2"
                             v-slide-in="{direction: 'X',distance_x:['10%', '0px'],distance_y:['0px', '0px'],duration: 1500}"
                             style="top: -50px; right: 0"
                             :src="item.thumbnailUrl" :alt="item.thumbnailUrl">
                        <div class="panel-3"
                             v-slide-in="{direction: 'X',distance_x:['-10%', '0px'],distance_y:['0px', '0px'],duration: 2000}"
                             style="left: 0">
                            {{ item.note }}
                        </div>
                    </div>
                    <div v-else class="item-position">
                        <div class="item-title"
                             v-slide-in="{direction: 'X',distance_x:['0px', '0px'],distance_y:['0px', '0px'],duration: 2000}"
                             style="top: -150px;right: 0;text-align: right">
                            {{ item.name }}
                        </div>
                        <div class="panel-1"
                             v-slide-in="{direction: 'X',distance_x:['-10%', '0px'],distance_y:['0px', '0px'],duration: 1000}"
                             style="top: 10px;left: 90px"></div>
                        <img class="panel-2"
                             v-slide-in="{direction: 'X',distance_x:['-10%', '0px'],distance_y:['0px', '0px'],duration: 1500}"
                             style="top: -50px; left: 0"
                             :src="item.thumbnailUrl" :alt="item.thumbnailUrl">
                        <div class="panel-3"
                             v-slide-in="{direction: 'X',distance_x:['10%', '0px'],distance_y:['0px', '0px'],duration: 2000}"
                             style="right: 0">
                            {{ item.note }}
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <our-students class="section-black" :ourStudentsNote="ourStudentsNote"
                      :bgOurStudentsNote="bgOurStudentsNote"></our-students>

        <section v-if="isLoading">
            <Faqs :cityId="city.id" class="section-black"></Faqs>
        </section>

        <section class="section-black last-vita">
            <div class="last-vita-content">
                <div class="img-content bg-last">
                    <img class="img" src="https://www.vitastudent.com/wp-content/uploads/2022/11/Group-10.png" alt="">
                </div>
                <div class="content">
                    <div class="content-text text-center">
                        <div v-html="$t('city.areYouIn')"></div>
                        <div class="link mt-3 cursor">
                            <div class="ms-auto me-auto d-flex align-items-center" @click="$goto('/landing-page')">
                                <div class="join-us">{{ $t('city.joinUs') }}</div>
                                <div class="go-btn icon iconfont">&#xe60d;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>

import Faqs from '@/components/faqs'
import {getCityAPI, submitConsultsAPI} from '@/apis/common'
import ourStudents from '@/components/our-students'

export default {
    name: "city",
    components: {
        Faqs,
        ourStudents
    },
    data() {
        return {
            checked: true,
            form: {
                name: null,
                cellphone: null,
                source: 'pc-city',
                cityId: null,
                apartmentId: null
            },
            isLoading: false,
            swiperOption: {
                pagination: false,
                autoplay: false,
                loop: true
            },
            swiperOptionVideo: {
                pagination: false,
                autoplay: false,
                loop: true,
                slidesPerView: 2,
                spaceBetween: 10,
            },
            currentImage: '',
            imgs: [
                'https://www.vitastudent.com/wp-content/uploads/2022/12/005-Belfast-Reception-C4-1-scaled.jpg?w=768',
                'https://www.vitastudent.com/wp-content/uploads/2022/12/005-Belfast-Study-C4-1-scaled.jpg?w=768',
                'https://www.vitastudent.com/wp-content/uploads/2022/12/005-Belfast-Gym-C4-1-scaled.jpg?w=768',
                'https://www.vitastudent.com/wp-content/uploads/2022/12/005-Belfast-Private-Dining-C4-1-scaled.jpg?w=768'
            ],
            city: null,
            activeSlideIndex: 0, // 设置当前幻灯片索引的默认值
        }
    },
    computed: {
        ourStudentsNote: function () {
            return this.$t('city.ourStudentsNote')
        },
        bgOurStudentsNote: function () {
            return 'ALL INCLUSIVE LIVING'
        },
        cityData() {
            if (!this.city) return null
            let isCn = this.$i18n.locale === 'cn' ? true : false
            return {
                name: isCn ? this.city.name : this.city.enName
            }
        },
        headData() {
            let lang = this.$i18n.locale === 'en' ? 2 : 1
            if (!this.city) return null
            return this.city.sections.find(obj => obj.lang === lang && obj.type === 4);
        },
        section2Data() {
            let lang = this.$i18n.locale === 'en' ? 2 : 1
            if (!this.city) return null
            return this.city.sections.find(obj => obj.lang === lang && obj.type === 5);
        },
        section3Data() {
            let lang = this.$i18n.locale === 'en' ? 2 : 1
            if (!this.city) return null
            return this.city.sections.find(obj => obj.lang === lang && obj.type === 6);
        },
        introduction() {
            let isCn = this.$i18n.locale === 'cn' ? true : false
            if (!this.city && !this.city.introduction) return null
            let sections = [];
            this.city.introduction.sections.forEach((item, index) => {
                sections.push({
                    desc: isCn ? item.descCn : item.descEn,
                    imagePosition: item.imagePosition,
                    title: isCn ? item.titleCn : item.titleEn,
                    thumbnailUrl: item.thumbnailUrl
                })
            })
            return {
                thumbnailUrl: this.city.introduction.thumbnailUrl,
                sections: sections
            }
        },
        universities() {
            let isCn = this.$i18n.locale === 'cn' ? true : false
            if (!this.city && !this.city.universities) return null
            let universities = [];
            this.city.universities.forEach((item, index) => {
                universities.push({
                    universityId: item.universityId,
                    name: isCn ? item.name : item.nameEn,
                    note: isCn ? item.note : item.noteEn,
                    title: isCn ? item.titleCn : item.titleEn,
                    thumbnailUrl: item.thumbnailUrl
                })
            })
            return universities
        },
        apartments() {
            let isCn = this.$i18n.locale === 'cn' ? true : false
            let lang = this.$i18n.locale === 'en' ? 2 : 1
            if (!this.city || this.city.apartments.length === 0) return null
            let apartments = [];
            this.city.apartments.forEach((item, index) => {
                let note = item.sections.find(obj => obj.lang === lang && obj.type === 9);
                apartments.push({
                    note: note ? note.content : '',
                    address: item.address,
                    apartmentId: item.apartmentId,
                    city: item.city,
                    name: isCn ? item.name : item.cnName,
                    consultBgWxUrl: item.consultBgWxUrl,
                    coverUrl: item.coverUrl,
                    floor: item.floor,
                    id: item.id,
                    longThumbnailUrl: item.longThumbnailUrl,
                    roomNum: item.roomNum,
                    score: item.score,
                    slug: item.slug,
                    squareThumbnailUrl: item.squareThumbnailUrl,
                    tag: item.tag,
                    thumbnailUrl: item.thumbnailUrl,
                    toDowntownTime: item.toDowntownTime,
                    toDowntownTimeDesc: isCn ? item.toDowntownTimeDescCn : item.toDowntownTimeDescEn,
                    toUnivesityTime: item.toUnivesityTime,
                    toUnivesityTimeDesc: isCn ? item.toUnivesityTimeDescCn : item.toUnivesityTimeDescEn,
                    walkingTime: item.walkingTime,
                    pcImgs: item.pcImgs
                })
            })
            return apartments
        },
        imgWalls() {
            return [
                {
                    title: this.$t('city.imgWallTitle1'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-1.png',
                    note: this.$t('city.imgWallNote1')
                },
                {
                    title: this.$t('city.imgWallTitle2'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-2.png',
                    note: this.$t('city.imgWallNote2')
                },
                {
                    title: this.$t('city.imgWallTitle3'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-3.png',
                    note: this.$t('city.imgWallNote3')
                },
                {
                    title: this.$t('city.imgWallTitle4'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-4.png',
                    note: this.$t('city.imgWallNote4')
                },
                {
                    title: this.$t('city.imgWallTitle5'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-5.png',
                    note: this.$t('city.imgWallNote5')
                },
                {
                    title: this.$t('city.imgWallTitle6'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-6.png',
                    note: this.$t('city.imgWallNote6')
                },
                {
                    title: this.$t('city.imgWallTitle7'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-7.png',
                    note: this.$t('city.imgWallNote7')
                },
                {
                    title: this.$t('city.imgWallTitle8'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-8.png',
                    note: this.$t('city.imgWallNote8')
                },
                {
                    title: this.$t('city.imgWallTitle9'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-9.png',
                    note: this.$t('city.imgWallNote9')
                },
                {
                    title: this.$t('city.imgWallTitle10'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-10.png',
                    note: this.$t('city.imgWallNote10')
                },
                {
                    title: this.$t('city.imgWallTitle11'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-11.png',
                    note: this.$t('city.imgWallNote11')
                },
                {
                    title: this.$t('city.imgWallTitle12'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-12.png',
                    note: this.$t('city.imgWallNote12')
                },
                {
                    title: this.$t('city.imgWallTitle13'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-13.png',
                    note: this.$t('city.imgWallNote13')
                },
                {
                    title: this.$t('city.imgWallTitle14'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-14.png',
                    note: this.$t('city.imgWallNote14')
                },
                {
                    title: this.$t('city.imgWallTitle15'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-15.png',
                    note: this.$t('city.imgWallNote15')
                },
                {
                    title: this.$t('city.imgWallTitle16'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-16.png',
                    note: this.$t('city.imgWallNote16')
                },
                {
                    title: this.$t('city.imgWallTitle17'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-17.png',
                    note: this.$t('city.imgWallNote17')
                },
                {
                    title: this.$t('city.imgWallTitle18'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-18.png',
                    note: this.$t('city.imgWallNote18')
                },
                {
                    title: this.$t('city.imgWallTitle19'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-19.png',
                    note: this.$t('city.imgWallNote19')
                },
                {
                    title: this.$t('city.imgWallTitle20'),
                    url: 'https://static.vitaalumnicn.com/static/vsweb/city-2-20.png',
                    note: this.$t('city.imgWallNote20')
                },
            ]
        }
    },
    created() {
        this.currentImage = this.imgWalls[0].url;
        this.initData(this.$route.params.slug);
    },
    methods: {
        handleSubmit(apartmentId) {
            if(this.checked === false) {
                this.$message({
                    type: 'warning',
                    message: this.$t('common.agreenChecked')
                })
                return
            }
            let form = this.form
            form.apartmentId = apartmentId
            form.cityId = this.city.cityId
            submitConsultsAPI(form).then(res => {
                if (res.data.code === 200) {
                    this.$message({
                        type: 'success',
                        message: this.$t('common.successfully')
                    })
                }
            })
        },
        initData(slug) {
            this.isLoading = false
            getCityAPI(slug).then(res => {
                if (res.data.code === 200) {
                    this.city = res.data.data
                    this.isLoading = true
                    this.$initMeta(this.city.pageTitle ? this.city.pageTitle : null,
                        this.city.pageKeywords ? this.city.pageKeywords : null,
                        this.city.pageDesc ? this.city.pageDesc : null)
                }
            })
        },
        handleSpeakToUs(index) {
            const formArea = document.getElementById("formArea_" + index);
            formArea.style.transition = "transform 0.3s ease";
            formArea.style.transform = "translateY(-100%)";
            formArea.style.bottom = "0";
        },
        closeSpeakToUs(index) {
            const formArea = document.getElementById("formArea_" + index);
            formArea.style.transition = "transform 0.3s ease";
            formArea.style.transform = "translateY(0)";
            formArea.style.bottom = "-100%";
        },
        onSlideChange(e) {
            this.activeSlideIndex = this.$refs.citySwiper2.swiper.realIndex;
        },
        toggleHover(url) {
            if (this.currentImage !== url) {
                this.currentImage = url;
                this.$forceUpdate();
            }
        },
        prevSlide(swiper, index = null) {
            if (index === null) {
                this.$refs[swiper].swiper.slidePrev();
            } else {
                this.$refs.citySwiper1[index].swiper.slidePrev();
            }
        },
        nextSlide(swiper, index = null) {
            if (index === null) {
                this.$refs[swiper].swiper.slideNext();
            } else {
                this.$refs.citySwiper1[index].swiper.slideNext();
            }
        },
    }
}
</script>

<style lang="less" scoped>
@import "./city.less";

</style>
